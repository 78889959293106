import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppService } from 'src/app/app.service';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { HomeService } from '../../Home-Modules/home/home.service';
import {ORG_DETAILS, ENDPOINTS} from 'src/app/app.config';


@Injectable({
  providedIn: 'root'
})
export class CartNewService {

  allProducts = [];
  public outletId;

  private data = new BehaviorSubject('');
  currentData = this.data.asObservable()

  constructor(
    private localService: LocalStorageService,
    private appService: AppService,
    private homeService: HomeService
  ) { this.getAllProducts(); }

  updateAdminFormEvent(item: any) {
    this.data.next(item);
  }

  clearEvent() {
    this.data = new BehaviorSubject('');
    this.currentData = this.data.asObservable();
  }

  updateToCart(products){
    this.localService.set('cart', JSON.stringify(products));
    this.updateAdminFormEvent({type:'cartData', value: products});
    this.updateAPICartDetails(products);
  }

  getCartItems(){
    if(this.localService.get('cart') !== null){
      return JSON.parse(this.localService.get('cart'));
    }
    else{
      return null;
    }
  }

  updateAPICartDetails(products){
    if(this.localService.get('cart') !== null && this.localService.get('session_id') !== null){
      let cartItems = JSON.parse(this.localService.get("cart"));
      let session_id = this.localService.get("session_id");
      if(session_id !== ""){
        let cart_data = {
          products : cartItems,
          session_id : session_id,
          status : 'active',
          total_quantity : cartItems.length > 0 ? cartItems.map(function (sp){return sp.package_quantity}).reduce((a,b) => a + b) : 0,
          total_items : cartItems.length,
          sub_total : this.findTotalAmount(cartItems), 
          userId: this.localService.get("UserId") ? this.localService.get("UserId") : ""
        }

        let formDataJson = 
        {
          domain_name : ORG_DETAILS.DOMAIN_NAME,
          user_id : ORG_DETAILS.USER_ID,
          payload : {
            cart_creation : cart_data
          }
        }
        this.appService.sendCartDetails(formDataJson).subscribe(resp=>
          {},
          err => {}
          )
        
      }
    }
  }
  
  findTotalAmount(products){
    if(products.length > 0){
      let totalAmount1 = 0;
      products.forEach(product => {
        console.log(product)
        let totalAmount = 0;
        // if(product.bundle_products && product.bundle_products.length > 0){
        //   product.bundle_products.forEach(element => {
        //     totalAmount += this.allProducts.find(pd => pd.id === element) !== undefined ? this.allProducts.find(pd => pd.id === element).pricing.raw.price : 0;
        //   });
        // }
        // if(product.add_onproducts && product.add_onproducts.length > 0){
        //   product.add_onproducts.forEach(element => {
        //     totalAmount += this.allProducts.find(pd => pd.id === element) !== undefined ? this.allProducts.find(pd => pd.id === element).pricing.raw.price : 0;
        //   });
        // }
        totalAmount += product.package_total;
        totalAmount1 += (totalAmount * product.package_quantity)
      });
      return totalAmount1;
    }
    else{
      return 0;
    }
  }

  getAllProducts(){
    this.outletId = this.appService.outletId;
    let requestData = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: null,
      outlet_id:Number(this.outletId),
      extras: {
        find:{
          // outlet_id:this.outletId
        },
        pagination: false,
          paginationDetails: {
            limit:10,
            pagesize: 20
          },
        sorting:false,
        sortingDetails: {
          sortfield: "id",
          sortorder: -1
        }
      }
      
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
      resp => {
        let res:any = resp;
        if(res.status_code === 200 && res.success === 1){
          this.allProducts = res.result.data;
        }
      }
    )
  }
}
