import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertsComponent } from "./alerts.component";
import { AlertsService } from "./alerts.service";

@NgModule ({
    declarations: [
        AlertsComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AlertsComponent
    ],
    providers: [
    ]
})

export class AlertsModule { };