import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {
  private callFunctionFlag: boolean = false;

  triggerFunctionCall() {
    this.callFunctionFlag = true;
  }

  shouldCallFunction(): boolean {
    return this.callFunctionFlag;
  }

  resetFlag() {
    this.callFunctionFlag = false;
  }
}