import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MiniCartModule } from "../mini-cart/mini-cart.module";
import { NavbarComponent } from "./navbar.component";

@NgModule ({
    declarations: [
        NavbarComponent
    ],
    imports: [
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        MiniCartModule,
        CommonModule
    ],
    exports: [
        NavbarComponent
    ]
})

export class NavbarModule { };