<div  *ngIf="isLoading" id="pause" class="d-flex align-items-center justify-content-center">
    <div>
        <div >
            <h4>Loading...</h4><br><br><br><br>
        </div>
        <div *ngIf="loaderMsg == 'Payment'" >
            <h4 class="text-white">Please wait, We are processing...</h4><br><br><br><br>
        </div>
        <div *ngIf="loaderMsg == 'customize'" >
            <h4 class="text-white" style="padding-bottom: 20px;">Please wait... Loading Yummy food for you!</h4><br><br><br><br>
        </div>
    </div>
    <div id="spinner"></div>
</div>
