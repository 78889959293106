import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MiniCartModule } from "../mini-cart/mini-cart.module";
import { NewNavbarComponent } from "./new-navbar.component";
import { NewNavbarService } from './new-navbar.service';

@NgModule ({
    declarations: [
        NewNavbarComponent
    ],
    imports: [
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        MiniCartModule,
        CommonModule
    ],
    exports: [
        NewNavbarComponent
    ]
})

export class NewNavbarModule { };