import { Injectable } from '@angular/core';

import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutDeliveryStorageService {

  DELIVERI_LABEL = "deliveryInfo";

  constructor(
    private localService: LocalStorageService
  ) { }

  setDeliveryInfo(deliveryInfo){
    this.localService.set(this.DELIVERI_LABEL, JSON.stringify(deliveryInfo))
  }

  getDeliveryInfo(){
    if(this.localService.get(this.DELIVERI_LABEL)){
      return JSON.parse(this.localService.get(this.DELIVERI_LABEL));
    }
    else{
      return null;
    }
  }

  removeDeliveryInfo(){
    if(this.localService.get(this.DELIVERI_LABEL)){
      this.localService.remove(this.DELIVERI_LABEL);
    }
  }
}
