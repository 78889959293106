import { NgModule } from "@angular/core";
import { SharedVariantsComponent } from "./shared-variants.component";

@NgModule ({
    declarations: [
        SharedVariantsComponent
    ],
    exports: [
        SharedVariantsComponent
    ]
})

export class SharedVariantsModule { };