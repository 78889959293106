import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderSummaryInitialService {

  private data = new BehaviorSubject('');
  currentData = this.data.asObservable()

  constructor() { }

  updateAdminFormEvent(item: any) {
    this.data.next(item);
  }

  clearEvent() {
    this.data = new BehaviorSubject('');
    this.currentData = this.data.asObservable();
  }
}
