import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private data = new BehaviorSubject('');
  private currentValue = new BehaviorSubject('')
  currentData = this.data.asObservable()
  currentValues=this.currentValue.asObservable()

  constructor() { }

  updateAdminFormEvent(item: any) {
    this.data.next(item);
  }
  setCurrentScreen(value : string)

  {

    this.currentValue.next(value);

  }
  clearEvent() {
    this.data = new BehaviorSubject('');
    this.currentData = this.data.asObservable();
 
  }
}
