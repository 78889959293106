import { NgModule } from "@angular/core";
import { MinValidator, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MiniCartModule } from "src/app/Components/mini-cart/mini-cart.module";
import { MenuModule } from "../menu/menu.module";
import { HeaderTwoComponent } from "./header-two.component";
import { NavbarModule } from "src/app/Components/navbar/navbar.module";
import { NewNavbarModule } from "src/app/Components/new-navbar/new-navbar.module";
import { NewNavbarModuleKub } from "src/app/Components/new-navbar-kubendran/new-navbar.module";
import { AlertsModule } from "src/app/Components/alerts/alerts.module";
import { CommonModule } from "@angular/common";@NgModule ({

    declarations: [
        HeaderTwoComponent
    ],
    imports: [
        NavbarModule,
        NewNavbarModule,
        NewNavbarModuleKub,
        AlertsModule,
        CommonModule,
        RouterModule,
        MiniCartModule,
        ReactiveFormsModule,
        MenuModule
    ],
    exports: [
        HeaderTwoComponent
    ]

})

export class HeaderTwoModule { };