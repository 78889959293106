import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FooterTwoComponent } from "./footer-two.component";
import { ReactiveFormsModule } from "@angular/forms";
import { AlertsModule } from 'src/app/Components/alerts/alerts.module';

@NgModule ({

    declarations: [
        FooterTwoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        AlertsModule,
    ],
    exports: [
        FooterTwoComponent
    ]

})

export class FooterTwoModule { };