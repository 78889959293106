import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, 
RouterStateSnapshot } from '@angular/router';
import { AppService } from 'src/app/app.service';
import {LocalStorageService} from 'src/app/Services/auth-service/local-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

constructor(private appService : AppService,private localService : LocalStorageService) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
{
    this.localService.set("InCheckout","false")
    this.appService.trackSession();
    return true   
}

}