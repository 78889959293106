import { NgModule } from "@angular/core";
import { LoaderComponent } from "./loader.component";
import { LoaderService } from "./loader.service";

@NgModule ({
    
    declarations: [
        LoaderComponent,
    ],
    imports: [

    ],
    exports: [
        LoaderComponent
    ],
    providers: [
        LoaderService
    ]

})

export class LoaderModule { };