import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedVariantsModule } from "src/app/Lazy-loaded-Modules/Product-Modules/product-lists/product-list/shared-variants/shared-variants.module";
import { AlertsModule } from "../alerts/alerts.module";
import { BannerComponent } from "./banner.component";
import { BannerTwoComponent } from "../banner-two/banner-two.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { MinValidator, ReactiveFormsModule } from "@angular/forms";

@NgModule ({
    declarations: [
        BannerComponent,
        BannerTwoComponent
    ],
    exports: [
        BannerComponent,
        BannerTwoComponent
    ],
    imports: [
        CommonModule,
        SharedVariantsModule,
        AlertsModule,
        RouterModule,
        ReactiveFormsModule
    // CommonModule,
    // HomeTwoRoutingModule,
    // CarouselModule,
    // AlertsModule,
    // FeaturedCollectionsModule,
    // ProductBoxOneModule,
    // SharedVariantsModule,
    // RouterModule,
    // BestProductsModule
    ]
})

export class BannnerModule {};