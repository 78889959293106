<header *ngIf="config.title != 'Murgasen' && configs.title !== 'Nesma' && configs.title !=='Mayan'" class="sticky-header shadow-sm">
    <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != '' && configs.title !== 'Murgasen' && configs.title !== 'Tams' && configs.title !== 'Nesma' && configs.title !== 'Mayan'">
        <div class="text-center">
            <p>
                <span [innerHtml]="promotionBanner"></span>
                <!-- <span>
                    <a href="#"><strong> More Details</strong></a>
                </span> -->
            </p>
            <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                    </a></span></p> -->
            <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
        </div>
    </div>
    <div class="inq-hmid-st inq-row">
    <div class="container" *ngIf="config.title == 'Haven'">
        <div class="inq-hmid1-st">


            <ul class="nav nav-tabs">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                        aria-haspopup="true" aria-expanded="false"><i class="fa fa-map-marker"></i>{{selectedValue}}</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item"  *ngFor="let item of branches" (click)="branchChange(item)" href="#">{{item.name}}</a>

                        <!-- <a class="dropdown-item" href="#"> Kodambakkam</a>
                        <a class="dropdown-item" href="#"> Tambaram</a> -->
                    </div>
                </li>
            </ul>
        </div>
        <div class="inq-hmid2-st">
            <a> <i class="fa fa-phone"></i> {{mobileNumber}}</a>
        </div>

    </div>
    </div>
    
    <app-navbar></app-navbar>
    <hr class="inq-hr-st">
</header>
<header *ngIf="config.title == 'Murgasen' || configs.title === 'Nesma'" class="sticky-header shadow-sm">
    <div style="min-height: 80px; max-height: 80px;">
        <app-navbar></app-navbar>
    </div>
</header>
<header *ngIf="config.title == 'Mayan'" class="sticky-header shadow-sm">
    <div style="min-height: 80px; max-height: 80px;">
        <app-navbar></app-navbar>
    </div>
</header>
<app-alerts *ngIf="showalertmsg"></app-alerts>
<div class="container-fluid navbar-row" *ngIf="config.title != 'Tams' && config.title != 'Mayan'">
    <app-menu></app-menu>
</div>

<div id="desktopMenu" class="container" *ngIf="config.title =='Tams'">
    <div class="inq-menu-st row justify-content-center">
        <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
            <div id="navbarNav" class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                            href="#/">Home</a></li>
                    <li class="nav-item" id="products"><a class="nav-link menu-text" ng-reflect-router-link="product"
                            href="#/product">FLOURS</a></li>
                    <li *ngIf="!tamsProduct" class="nav-item" id="prepare"><a class="nav-link menu-text" ng-reflect-router-link="product"
                                (click)="openLink()">HOW TO PREPARE</a></li>
                    <div *ngIf="tamsProduct && tamsProduct.videos && tamsProduct.videos.length > 0" class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle nav-item" style="background-color: white !important; color: rgb(9, 64, 75)
                         !important;border: none !important;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            HOW TO PREPARE
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <div *ngFor="let video of tamsProduct.videos">
                                <a class="dropdown-item" (click)="playVideo(video)">Play in {{video.language}}</a>
                            </div>
                        </div>
                        </div>
                    <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                            href="#/about-us">About Us</a></li>
                    <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                            href="#/contact-us">Contact Us</a></li>
                            
                </ul>
            </div>
        </nav>
    </div>
</div>

<div id="desktopMenu1" class="container" *ngIf="config.title == 'Mayan'">
    <div class="inq-menu-st row justify-content-center">
        <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
            <div id="navbarNav" class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                            href="#/">Home</a></li>
                    <li class="nav-item" id="menus"><a class="nav-link menu-text" ng-reflect-router-link="product"
                            href="#/product">SHOP</a></li>
                    <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                            href="#/about-us">About Us</a></li>
                    <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                            href="#/contact-us">Contact Us</a></li>
                </ul>
            </div>
        </nav>
    </div>
</div>
<hr class="inq-hr1-st">
<div (click) = "closeVideo()">
    <button id="launch-video" type="button" style="display: none !important;" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
        Launch demo modal
    </button>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary" id="exampleModalLabel">iLY Flour Instruction Video</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeVideo()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="padding: 0px !important;">
                    <iframe width="100%" height="325" [src]="tamsVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" style="padding: 5px 15px !important;" (click)="closeVideo()">Close</button>
                  </div>
            </div>
        </div>
    </div>
</div>
    