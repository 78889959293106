import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertsModule } from "src/app/Components/alerts/alerts.module";
import { BannnerModule } from "src/app/Components/banner/banner.module";
import { MiniCartModule } from "src/app/Components/mini-cart/mini-cart.module";
import { NavbarModule } from "src/app/Components/navbar/navbar.module";
import { MenuModule } from "../menu/menu.module";
import { HeaderComponent } from "./header.component";

@NgModule ({

    declarations: [
        HeaderComponent
    ],
    imports: [
        AlertsModule,
        NavbarModule,
        MenuModule,
        CommonModule,
        MiniCartModule,
        BannnerModule
    ],
    exports: [
        HeaderComponent
    ]

})

export class HeaderModule { };