import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AlertsModule } from "src/app/Components/alerts/alerts.module";
import { FooterComponent } from "./footer.component";

@NgModule({

    declarations: [
        FooterComponent,
    ],
    imports: [
        CommonModule,
        AlertsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    exports: [
        FooterComponent
    ],
    providers: [
        
    ]

})

export class FooterModule { };