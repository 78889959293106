import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import {ENDPOINTS, ORG_DETAILS} from 'src/app/app.config';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutApiService {

  commonRequestData = {
    "domain_name": ORG_DETAILS.DOMAIN_NAME,
    "user_id": ORG_DETAILS.USER_ID,
    "extras": {
      "find": {
      }
    }
  }
  
  respObject = {
    success:true,
    data:{},
    message:""
  }

  constructor(
    public appService: AppService,
    private localService:LocalStorageService,
  ) { }
  outletvalue= this.appService.outletId;
  
  
  outletRequestData = {
    "domain_name": ORG_DETAILS.DOMAIN_NAME,
    "user_id": ORG_DETAILS.USER_ID,
    "extras": {
      "find": {
        "id":Number(this.outletvalue)
      }
    }
  }
  checkoutAPICall(endPoint:string, reqData?:any){
    return new Promise((resolve, reject) => {
      var respObject = {
        success:true,
        data:{},
        message:""
      }
      this.appService.postApiCall(reqData ? reqData:this.commonRequestData, endPoint).subscribe(
      resp1 => {
        let resp = resp1;
        if(resp.success === 1){
          respObject.success = true;
          if(resp.result){
            respObject.data = resp.result.data;
          }
          else{
            respObject = resp;
          }
         if(resp.message){
           respObject.message = resp.message;
         }
         resolve(respObject);
        }
        else if(resp.success === 0){
          if(resp.result){
            if(resp.result.data.deliveryCharge){
              respObject.success = true;
              if(resp.result){
                respObject.data = resp.result.data;
              }
              else{
                respObject = resp;
              }
              if(resp.message){
                respObject.message = resp.message;
              }
              resolve(respObject);
            }
            else{
              respObject.success = false;
              respObject.data = resp;
              if(resp.message){
                respObject.message = resp.message;
              }
              resolve(respObject)
            }
          }
          else{
            respObject.success = false;
            respObject.data = resp;
            if(resp.message){
              respObject.message = resp.message;
            }
            resolve(respObject)
          }
        }
        else{
          respObject.success = false;
          if(resp.message){
            respObject.message = resp.message;
          }
          else{
            respObject.message = 'Something bad happened; Please try again!';
          }
          resolve(respObject);
        }
      },
      err => {
        respObject.success = false;
        if(err.error.statusCode === 401){
          respObject.message = "Your password is invalid. Please try again."
        }
        else if(err.error.statusCode === 500){
          respObject.message = err.error.message;
        }
        else if (err.error.error) {
          if(err.error.error.message){
            respObject.message = err.error.error.message
          }
        }
        else{
          respObject.message = 'Something bad happened; Please try again!';
        }
        resolve(respObject);
      })
    })
  }
  outletAPICall(endPoint:string, reqData?:any){
    return new Promise((resolve, reject) => {
      var respObject = {
        success:true,
        data:{},
        message:""
      }
      console.log(this.outletvalue,"this.outletvalue")
      if(this.outletvalue){
        this.appService.postApiCall(reqData ? reqData:this.outletRequestData, endPoint).subscribe(
          resp1 => {
            let resp = resp1;
            if(resp.success === 1){
              respObject.success = true;
              if(resp.result){
                respObject.data = resp.result.data;
              }
              else{
                respObject = resp;
              }
             if(resp.message){
               respObject.message = resp.message;
             }
             resolve(respObject);
            }
            else if(resp.success === 0){
              if(resp.result){
                if(resp.result.data.deliveryCharge){
                  respObject.success = true;
                  if(resp.result){
                    respObject.data = resp.result.data;
                  }
                  else{
                    respObject = resp;
                  }
                  if(resp.message){
                    respObject.message = resp.message;
                  }
                  resolve(respObject);
                }
                else{
                  respObject.success = false;
                  respObject.data = resp;
                  if(resp.message){
                    respObject.message = resp.message;
                  }
                  resolve(respObject)
                }
              }
              else{
                respObject.success = false;
                respObject.data = resp;
                if(resp.message){
                  respObject.message = resp.message;
                }
                resolve(respObject)
              }
            }
            else{
              respObject.success = false;
              if(resp.message){
                respObject.message = resp.message;
              }
              else{
                respObject.message = 'Something bad happened; Please try again!';
              }
              resolve(respObject);
            }
          },
          err => {
            respObject.success = false;
            if(err.error.statusCode === 401){
              respObject.message = "Your password is invalid. Please try again."
            }
            else if(err.error.statusCode === 500){
              respObject.message = err.error.message;
            }
            else if (err.error.error) {
              if(err.error.error.message){
                respObject.message = err.error.error.message
              }
            }
            else{
              respObject.message = 'Something bad happened; Please try again!';
            }
            resolve(respObject);
          })

      }
     else{
      this.appService.postApiCall(reqData ? reqData:this.commonRequestData, endPoint).subscribe(
        resp1 => {
          let resp = resp1;
          if(resp.success === 1){
            respObject.success = true;
            if(resp.result){
              respObject.data = resp.result.data;
            }
            else{
              respObject = resp;
            }
           if(resp.message){
             respObject.message = resp.message;
           }
           resolve(respObject);
          }
          else if(resp.success === 0){
            if(resp.result){
              if(resp.result.data.deliveryCharge){
                respObject.success = true;
                if(resp.result){
                  respObject.data = resp.result.data;
                }
                else{
                  respObject = resp;
                }
                if(resp.message){
                  respObject.message = resp.message;
                }
                resolve(respObject);
              }
              else{
                respObject.success = false;
                respObject.data = resp;
                if(resp.message){
                  respObject.message = resp.message;
                }
                resolve(respObject)
              }
            }
            else{
              respObject.success = false;
              respObject.data = resp;
              if(resp.message){
                respObject.message = resp.message;
              }
              resolve(respObject)
            }
          }
          else{
            respObject.success = false;
            if(resp.message){
              respObject.message = resp.message;
            }
            else{
              respObject.message = 'Something bad happened; Please try again!';
            }
            resolve(respObject);
          }
        },
        err => {
          respObject.success = false;
          if(err.error.statusCode === 401){
            respObject.message = "Your password is invalid. Please try again."
          }
          else if(err.error.statusCode === 500){
            respObject.message = err.error.message;
          }
          else if (err.error.error) {
            if(err.error.error.message){
              respObject.message = err.error.error.message
            }
          }
          else{
            respObject.message = 'Something bad happened; Please try again!';
          }
          resolve(respObject);
        })
     }
    })
  }
}
